<template>
	<div
		id="deal"
		class="px-3 pt-4 md:px-5"
	>
		<div class="grid grid-cols-1 lg:grid-cols-2">
			<div
				v-if="showClaimButton"
				class="w-full aspect-[3/2] shrink-0"
			>
				<WwImage
					:src="dealImage"
					classes="rounded-xl"
					:alt="dealTitle"
					:width="400"
					:height="260"
					:image-path="DEAL_IMAGES"
					:lazy="false"
				/>
			</div>
			<WwCarousel
				v-show="!showClaimButton"
				:id="DEAL_CLAIM_CAROUSEL"
				:ref="DEAL_CLAIM_CAROUSEL"
				:item-count="2"
				:items-per-slide="1"
				:initial-slide="2"
				side-scroller-class="pb-6 lg:pb-0 snap-x snap-mandatory"
				scrollbar-visibility="hide-horizontal-scrollbar"
				scroll-behavior="smooth"
				arrow-visibility="hidden"
				fade-visibility="hidden"
				footer-class="py-6 lg:-mt-2 lg:py-0"
				hide-header
				emit-scroll
				force-bullet-action
				class="lg:pb-9"
			>
				<template #slides>
					<div
						id="scroller-inner"
						:ref="DEAL_CLAIM_SCROLLER_INNER"
						class="flex w-full"
					>
						<div
							:id="CAROUSEL_ITEM"
							:ref="CAROUSEL_ITEM"
							class="w-full mt-auto aspect-3/2 lg:my-auto shrink-0 snap-center"
						>
							<WwImage
								:src="dealImage"
								classes="rounded-xl"
								:alt="dealTitle"
								:width="400"
								:height="260"
								:image-path="DEAL_IMAGES"
								:lazy="false"
								fetch-priority="high"
							/>
						</div>
						<div
							:id="CAROUSEL_ITEM"
							:ref="CAROUSEL_ITEM"
							class="w-full shrink-0 snap-center"
						>
							<div class="w-2/3 mx-auto lg:mx-0 lg:w-full shrink-0">
								<WwImage
									v-show="claimHash"
									:src="`https://wheresweed.com/qr_deals.php?h=${claimHash}`"
									alt="QR Code"
									:fallback-image="DEFAULT_WW_GRAY_LOGO"
									:width="100"
									:height="100"
								/>
							</div>
						</div>
					</div>
				</template>
			</WwCarousel>
			<div class="w-full lg:pl-4">
				<h1 class="pt-4 text-2xl font-bold lg:pt-2 lg:py-0">
					${{ dealAmount }} {{ dealTitle }}
				</h1>
				<p
					v-if="dealDescription.length"
					class="pt-2"
				>
					{{ dealDescription }}
				</p>
				<div class="flex justify-around pt-3 text-xl font-bold opacity-40">
					<div class="capitalize">
						{{ dealCategory }}
					</div>
					<div class="capitalize">
						{{ indication }}
					</div>
					<div class="flex justify-center">
						<img
							src="@/assets/icons/heart-full.svg"
							class="mx-2"
							alt="Heart Icon - Where's Weed"
							height="15"
							width="15"
						>
						<div>
							{{ dealClaimCount }}
						</div>
					</div>
				</div>
				<div
					v-show="!showClaimButton"
					class="flex flex-wrap w-full mt-4 lg:pb-4"
				>
					<div class="w-full">
						<div
							v-if="dealClaimed.text"
							class="p-2 my-1 text-lg font-bold text-center text-white bg-green-500 rounded"
						>
							Text sent
						</div>
						<div
							v-else
							class="my-1"
						>
							<WwCollapse
								show-icon
								active-title-classes="border-green-500 bg-green-500 text-white"
								active-icon-classes="text-white"
								title-classes="focus:ring focus:ring-green-300 justify-around p-2 text-xl font-bold border border-gray-300 rounded shadow hover:shadow-lg focus:border-gray-300"
								@collapsed="handleCollapse(TEL)"
							>
								<template #title>
									Text Deal
								</template>
								<template #content>
									<form
										:id="TEL"
										class="w-full"
										@submit.prevent="sendLink(TEL)"
									>
										<div class="mb-2 bg-gray-100 rounded-b shadow-inner">
											<div class="px-2 py-2">
												<WwTextInput
													:id="TEL"
													v-model="phone"
													:input-type="TEL"
													:required="true"
													placeholder="(XXX) XXX-XXXX"
													:value="phone"
													label="Phone Number"
													:state="formPhoneInputState"
													input-class="w-full"
													:autocomplete="TEL"
													@validity-changed="setPhoneValidity"
												/>
												<button
													:disabled="!!dealClaimed.text || !isValidPhone"
													class="text-white text-lg h-10 px-2.5 my-1 rounded font-bold focus:ring focus:ring-green-300"
													:class="isValidPhone ? 'bg-green-500' : 'bg-gray-300 cursor-not-allowed'"
													type="submit"
												>
													Send Text
												</button>
											</div>
										</div>
									</form>
								</template>
							</WwCollapse>
						</div>
					</div>
					<div class="w-full">
						<div
							v-if="dealClaimed.email"
							class="p-2 my-1 text-lg font-bold text-center text-white bg-green-500 rounded"
						>
							Email sent
						</div>
						<div
							v-else
							class="my-1"
						>
							<WwCollapse
								show-icon
								active-title-classes="border-green-500 bg-green-500 text-white"
								active-icon-classes="text-white"
								title-classes="focus:ring focus:ring-green-300 justify-around p-2 text-xl font-bold border border-gray-300 rounded shadow hover:shadow-lg focus:border-gray-300"
								@collapsed="handleCollapse(EMAIL)"
							>
								<template #title>
									Email Deal
								</template>
								<template #content>
									<form
										:id="EMAIL"
										class="w-full"
										@submit.prevent="sendLink(EMAIL)"
									>
										<div class="mb-2 bg-gray-100 rounded-b shadow-inner">
											<div class="px-2 py-2">
												<WwTextInput
													:id="EMAIL"
													v-model="email"
													:input-type="EMAIL"
													:required="true"
													:value="email"
													label="Email"
													placeholder="your@email.com"
													:state="isValidEmail"
													autocomplete="email"
													input-class="w-full"
												/>
												<button
													:disabled="isValidEmail === 'error'"
													class="text-white text-lg h-10 px-2.5 my-1 rounded font-bold focus:ring focus:ring-green-300"
													:class="isValidEmail === 'success' ? 'bg-green-500' : 'bg-gray-300 cursor-not-allowed'"
													type="submit"
												>
													Send Email
												</button>
											</div>
										</div>
									</form>
								</template>
							</WwCollapse>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script async>
import { mapGetters, mapMutations } from 'vuex'

import destructureDeal from '@/api/helpers/destructureDeal.js'
import WwCollapse from '@/components/multiUse/WwCollapse.vue'
import WwCarousel from '@/components/UI/WwCarousel.vue'
import WwTextInput from '@/components/UI/WwTextInput.vue'
import { useDealClaim } from '@/composables/dealClaim.js'
import CAROUSEL_ITEM from '@/constants/carousel/carousel-item.js'
import { DEAL_IMAGES } from '@/constants/images/image-folder-paths.js'
import { DEFAULT_WW_GRAY_LOGO } from '@/constants/search/defaultImage.js'
import INPUT_STATES from '@/constants/text-inputs/states.js'
import { EMAIL, TEL } from '@/constants/text-inputs/types.js'
import { ERROR, SUCCESS } from '@/constants/toast/type.js'
import SIDE_SCROLLER from '@/constants/UI/side-scroller.js'
import { ClaimDeal } from '@/gql/mutations/deals.gql'
import { componentLevelGQLErrors, logError } from '@/utils/error-handling.js'
import { inputFieldState } from '@/validators/text-inputs.js'

const DEAL_CLAIM_SCROLLER_INNER = 'dealClaimScroller'
const DEAL_CLAIM_CAROUSEL = 'dealClaimCarousel'
const LEFT = 'left'
const RIGHT = 'right'

export default {
	name: 'BusinessDealClaim',
	components: {
		WwImage: () => import('@/components/multiUse/WwImage.vue'),
		WwCollapse,
		WwTextInput,
		WwCarousel
	},
	props: {
		dealData: {
			type: Object,
			default: () => ({})
		},
		seoUrl: {
			type: String,
			default: ''
		},
		businessUrl: {
			type: String,
			default: ''
		}
	},
	emits: [ 'claimed' ],
	data() {
		return {
			isMounted: false,
			email: '',
			phone: '',
			dealClaimed: {
				text: false,
				email: false
			},
			claimHash: null,
			isValidPhone: false,
			useFallbackImage: false,
			EMAIL,
			TEL,
			INPUT_STATES,
			inputFieldState,
			DEFAULT_WW_GRAY_LOGO,
			DEAL_IMAGES,
			DEAL_CLAIM_SCROLLER_INNER,
			LEFT,
			RIGHT,
			CAROUSEL_ITEM,
			DEAL_CLAIM_CAROUSEL,
			SIDE_SCROLLER,
			initialSlide: 1,
			activeIndex: 0,
			observer: null,
			scrollTargets: null,
			intersectingIndex: 0,
			scrollerContainer: null
		}
	},
	computed: {
		...mapGetters('business', [ 'businessApiParam' ]),
		deal() {
			return destructureDeal(this.dealData)
		},
		totalSlides() {
			return this.showClaimButton ? 1 : 2
		},
		dealImage() {
			return this.deal?.image || ''
		},
		dealTitle() {
			return this.deal?.title || ''
		},
		dealAmount() {
			return this.deal?.amount || ''
		},
		dealDescription() {
			return this.deal?.description || ''
		},
		dealCategory() {
			return this.deal?.category || ''
		},
		dealClaimCount() {
			return this.deal?.dealClaimCount || 0
		},
		formPhoneInputState() {
			if (!this.phone?.length) return INPUT_STATES.ERROR
			return this.isValidPhone ? INPUT_STATES.SUCCESS : INPUT_STATES.ERROR
		},
		dealId() {
			return this.deal?.id
		},
		indication() {
			if (this.deal?.medical && this.deal?.recreational) return 'Med | Rec'
			if (this.deal?.medical) return 'Med'
			if (this.deal?.recreational) return 'Rec'
			return ''
		},
		showClaimButton() {
			return !this.claimHash
		},
		isValidEmail() {
			return inputFieldState(this.email, EMAIL)
		},
		dealClaimPath() {
			if (this.claimHash && this.$route.params?.deal_id) {
				return this.businessUrl + '/deals/view/' + this.$route.params.deal_id + '/claimed/' + this.claimHash
			} else {
				return null
			}
		}
	},
	watch: {
		isMounted: {
			handler(newVal) { // set initial slide to qr code on SSR load if claimHash is present
				if (newVal && this.isMounted && this.$route.params?.deal_id && this.$route.params?.claimHash) {
					this.claimHash = this.$route.params.claimHash
				}
			},
			immediate: true
		},
		claimHash(newValue) {
			if (newValue) {
				this.$router.push({ path: this.dealClaimPath })
				this.setClaimedState()
			}
		},
		intersectingIndex: { // scroll on active item change
			handler(newVal) {
				if (this.$refs?.[DEAL_CLAIM_SCROLLER_INNER]) {
					this.$refs?.[DEAL_CLAIM_CAROUSEL]?.setSlide(newVal, false)
				}
			},
			immediate: true
		}
	},
	beforeDestroy() {
		if (this.observer) {
			this.observer.disconnect()
		}
	},
	mounted() {
		this.isMounted = true
	},
	methods: {
		...mapMutations('toast', [ 'showToast' ]),
		setClaimedState() {
			this.$emit('claimed')
			this.activeIndex = 1 // set to second slide (QR code)
			setTimeout(() => {
				this.getScrollContainer() // get measurements
				this.startIO() // start watching for intersection
				this.scrollerContainer?.scrollTo({ // scroll on click for mobile only
					top: 0,
					left: this.scrollerContainer.scrollWidth,
					behavior: 'smooth'
				})
			}, 100)
		},
		startIO() {
			this.scrollTargets = Array.from(document.querySelectorAll(`#${CAROUSEL_ITEM}`))
			this.observer = new IntersectionObserver((entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						this.intersectingIndex = this.scrollTargets.indexOf(entry.target) + 1
					}
				})
			}, {
				root: this.scrollerContainer,
				rootMargin: '0px -50% 0px -50%', // sets our IO's window of observervation to the horizontal center of the element
				threshold: 0
			})
			for (const element of this.scrollTargets) {
				this.observer.observe(element)
			}
		},
		getScrollContainer() {
			this.scrollerContainer = this.$refs?.[DEAL_CLAIM_CAROUSEL]?.$children?.[1]?.$refs?.[SIDE_SCROLLER]
		},
		handleCollapse(inputType) { // reset phone input to fix bug with validation causing input to be hidden
			if (inputType === TEL) this.phone = ''
			if (inputType === EMAIL) this.email = ''
		},
		setPhoneValidity(validity) {
			this.isValidPhone = validity
		},
		async claimDeal() {
			// get hash
			useDealClaim(this.dealId)
			try {
				this.$apollo.mutate({
					mutation: ClaimDeal,
					variables: {
						dealId: parseInt(this.deal?.id),
						listingId: parseInt(this.deal?.listingId)
					},
					update: (_, { data: { dealClaim } }) => {
						this.claimHash = dealClaim.claimHash
					}
				})
			} catch (error) {
				componentLevelGQLErrors(error)
			}
		},
		async sendLink(type) {
			let claimResponse = {}
			let params = {}

			if (type === TEL) {
				params = Object.assign({},
					{
						deal_id: this.dealId,
						business: this.deal.listingId,
						phone: this.phone,
						claim_hash: this.claimHash
					}
				)
			} else if (type === EMAIL) {
				params = Object.assign({},
					{
						deal_id: this.dealId,
						business: this.deal.listingId,
						email: this.email,
						claim_hash: this.claimHash
					}
				)
			}

			this.dealClaimed[type] = true // disable the claim button for the selected claim type to prevent multiple clicks
			claimResponse = await this.$store.dispatch('newGet', this.getPostData(params)) // now send link
			this.handleToast(claimResponse)
			this.dealClaimed[type] = false // enable the claim button for the selected claim type so that the user can try again
		},
		handleToast(claimResponse) {
			const claimFailed = claimResponse?.data?.success === false && !!claimResponse?.data?.errors?.length
			if (claimFailed) {
				const error = new Error(claimResponse.data.errors[0].message)
				this.showToast({
					primaryText: 'Server Error',
					secondaryText: 'There was a problem claiming the deal you selected',
					type: ERROR
				})
				logError(error)
			} else {
				this.showToast({
					primaryText: claimResponse.data.results.deals?.message,
					secondaryText: claimResponse.data.results.deals?.deal?.title,
					type: SUCCESS
				})
			}
		},
		getPostData: function (params) {
			const endpoint = 'Deals/sendLink'

			return {
				endpoint: endpoint,
				params: params
			}
		},
		fieldState(value) {
			return value?.length ? INPUT_STATES.SUCCESS : INPUT_STATES.ERROR
		}
	}
}
</script>
