<template>
	<div class="w-full">
		<div
			v-if="hasDeals"
			class="flex flex-wrap justify-center w-full px-3 py-4 lg:mb-6 xl:justify-start gap-y-4 lg:gap-y-16 lg:pt-8 gap-x-1 md:gap-x-4 xl:gap-x-4"
		>
			<DealCard
				v-for="(deal, index) in deals"
				:key="index"
				:deal="deal"
			/>
		</div>
		<EmptyList
			v-else-if="!loading"
			:image-src="imgSrc"
			copy="This business has no deals listed."
			class="p-6 pt-8 mb-2 md:mt-4"
		/>
		<div
			v-if="loading"
			class="grid w-full grid-cols-1 gap-16 py-16 text-xl font-bold text-center"
		>
			<div class="w-full text-gray-300">
				Loading...
			</div>
			<LoadingSpinner class="w-1/4 mx-auto text-gray-300 md:w-1/12" />
		</div>
	</div>
</template>

<script async>
import { mapGetters, mapMutations } from 'vuex'

import destructureDeal from '@/api/helpers/destructureDeal.js'
import DealCard from '@/components/deal/DealCard.vue'
import EmptyList from '@/components/multiUse/EmptyList.vue'
import LoadingSpinner from '@/components/multiUse/LoadingSpinner.vue'
import { CONTENT_SERVER } from '@/constants/index.js'
import { GetBusinessDealsPageData } from '@/gql/business/queries/pages.gql'
import { componentLevelGQLErrors } from '@/utils/error-handling.js'

const PER_PAGE = 10
export default {
	components: {
		DealCard,
		EmptyList,
		LoadingSpinner
	},
	data() {
		return {
			currentPage: 0,
			hover: false,
			observer: null,
			lastElement: '',
			isMounted: false,
			loading: 0
		}
	},
	apollo: {
		dealsPageData: { // dont 404 if no deals found, just show empty list
			query: GetBusinessDealsPageData,
			variables() {
				return {
					limit: PER_PAGE,
					offset: 0,
					id: null,
					businessId: this.businessId
				}
			},
			update(data) {
				if (data.auth) {
					this.$store.commit('auth/setAuth', { auth: data.auth })
				}
				if (data?.listing?.deals?.[0]) {
					const deals = data?.listing?.deals
					return {
						businessName: data?.listing?.name,
						businessUrl: data?.listing?.url,
						deals
					}
				}
				return []
			},
			skip() {
				return !this.businessId
			},
			error(error) {
				componentLevelGQLErrors(error)
			}
		}
	},
	computed: {
		...mapGetters('modal', [ 'activeModalId' ]),
		...mapGetters('business', [ 'businessId' ]),
		businessData() {
			return {
				name: this.dealsPageData?.businessName,
				url: this.dealsPageData?.businessUrl
			}
		},
		deals() {
			return this.dealsPageData?.deals?.map(deal => { return destructureDeal(deal) })
		},
		hasDeals() {
			return !!this.deals?.length
		},
		imgSrc() {
			return `${CONTENT_SERVER}/img/no-deals.jpg`
		},
		hasLastElement() {
			return this.isElement(this.lastElement)
		},
		browserSupportsIO() {
			return window && 'IntersectionObserver' in window
		}
	},
	watch: {
		lastElement(_, oldVal) {
			if (this.hasLastElement && !oldVal) {
				this.startObserver()
			} else {
				this.changeObserver(this.lastElement)
			}
		}
	},
	mounted() {
		this.isMounted = true
		this.setScrollTargets()
	},
	beforeDestroy() {
		if (this.observer) {
			this.observer.disconnect()
		}
	},
	updated() {
		this.setScrollTargets()
	},
	methods: {
		...mapMutations('modal', [ 'showModal' ]),
		setScrollTargets() {
			if (this.hasDeals) {
				const scrollTargets = document.querySelectorAll('div.observe')
				this.lastElement = scrollTargets[scrollTargets.length - 1]
			}
		},
		observerCallback(entries, observer) {
			if (entries[0].isIntersecting && this.browserSupportsIO) {
				observer.unobserve(entries[0].target)
				this.getNextPage()
			}
		},
		startObserver() {
			if (this.hasLastElement && this.browserSupportsIO) {
				this.observer = new IntersectionObserver(this.observerCallback)
				this.observer.observe(this.lastElement)
			}
		},
		changeObserver(newElement) {
			if (this.hasLastElement && this.browserSupportsIO) {
				this.observer.observe(newElement)
			}
		},
		isElement(obj) {
			return obj instanceof HTMLElement
		},
		async getNextPage() {
			this.currentPage++

			try {
				await this.$apollo.queries.dealsPageData.fetchMore({
					variables: {
						offset: this.currentPage * PER_PAGE,
						limit: PER_PAGE,
						id: null
					},
					updateQuery(previousResult, { fetchMoreResult }) {
						let newResult = null
						let duplicateDeal = null

						if (!fetchMoreResult?.listing?.deals) {
							return previousResult
						}

						if (previousResult) { // check for duplicates
							previousResult.listing.deals.forEach(prevDeal => {
								if (fetchMoreResult.listing.deals.find(deal => deal.id === prevDeal.id)) {
									duplicateDeal = fetchMoreResult.listing.deals.find(deal => deal.id === prevDeal.id)
								}
							})
						}
						if (duplicateDeal) { // remove duplicates
							newResult = fetchMoreResult.listing.deals.filter(deal => deal.id !== duplicateDeal.id)
						} else {
							newResult = fetchMoreResult.listing.deals
						}

						return {
							listing: {
								id: previousResult.listing.id,
								name: previousResult.listing.name,
								url: previousResult.listing.url,
								deals: [
									...previousResult.listing.deals,
									...newResult
								],
								__typename: previousResult.listing.__typename
							},
							__typename: previousResult.__typename
						}
					}
				})
			} catch (error) {
				componentLevelGQLErrors(error)
			}
		}
	}
}
</script>
