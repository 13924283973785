<template>
	<div
		id="deal"
		class="w-full pb-8 xl:pb-0 md:max-w-4xl"
	>
		<template v-if="deal">
			<DealClaim
				ref="dealClaim"
				:deal-data="deal"
				:seo-url="seoUrl"
				:business-url="businessUrl"
				@claimed="showQRCode"
			/>
			<div class="flex w-full px-3 pt-4 pb-2 md:px-5">
				<button
					v-if="!showQR"
					class="w-full p-2 my-2 text-xl font-bold text-white bg-green-500 rounded shadow xl:hover:bg-green-400"
					@click="handleDealClaimButtonClick"
				>
					Claim Deal
				</button>
				<button
					v-else
					class="w-full p-2 my-2 text-xl font-bold text-white bg-green-500 rounded shadow xl:hover:bg-green-400"
					@click="handleViewMenuButtonClick"
				>
					View Menu
				</button>
			</div>
		</template>
	</div>
</template>

<script async>

import { mapGetters } from 'vuex'

import DealClaim from '@/components/deal/DealClaim.vue'
import { GetBusinessDealsPageData } from '@/gql/business/queries/pages.gql'
import { componentLevelGQLErrors } from '@/utils/error-handling.js'

export default {
	name: 'BusinessDealView',
	components: {
		DealClaim
	},
	data() {
		return {
			showQR: false
		}
	},
	apollo: {
		dealsPageData: { // dont 404 if no deals found, just show empty list
			query: GetBusinessDealsPageData,
			variables() {
				return {
					seoUrl: this.$route.params?.business,
					chainSeoUrl: this.$route.params?.chain || '',
					limit: 1,
					offset: 0,
					id: parseInt(this.dealId),
					businessId: this.businessId
				}
			},
			update(data) {
				if (data.auth) {
					this.$store.commit('auth/setAuth', { auth: data.auth })
				}
				if (data?.listing?.deals?.[0]) {
					const deal = data?.listing?.deals?.[0]
					return {
						businessUrl: data?.listing?.url,
						businessName: data?.listing?.name,
						deal
					}
				}
				return []
			},
			skip() {
				return !this.dealId.length || !this.businessId
			},
			error(error) {
				componentLevelGQLErrors(error)
			}
		}
	},
	metaInfo() {
		return {
			title: this.metaTitle,
			meta: [
				{ description: this.metaDescription }
			]
		}
	},
	computed: {
		...mapGetters('business', [ 'businessId' ]),
		dealId() {
			return this.$route.params?.deal_id || null
		},
		deal() {
			return this.dealsPageData?.deal || null
		},
		businessUrl() {
			return this.dealsPageData?.businessUrl || ''
		},
		businessName() {
			return this.dealsPageData?.businessName || ''
		},
		seoUrl() {
			return this.$route.params?.business || ''
		},
		metaTitle() {
			return `${this.deal?.title} - ${this.businessName} | Where's Weed`
		},
		metaDescription() {
			return this.deal?.description
		}
	},
	methods: {
		handleViewMenuButtonClick() {
			this.showQR = false
			this.$router.push({ path: this.businessUrl })
		},
		handleDealClaimButtonClick() {
			this.$refs.dealClaim.claimDeal()
		},
		showQRCode() {
			this.showQR = true
		}
	}
}
</script>
